import React from "react";

import "../css/About.scss";
import AboutUs from "../images/aboutus.jpeg";
import NavigationBreadcrumb from "../NavigationBreadcrumb";

const About = () => (
  <div className="container about">
    <NavigationBreadcrumb></NavigationBreadcrumb>
    <h1>About Us</h1>
    <img src={AboutUs} alt="Onvet"></img>
    <div className="about-info">
      <p>
        ONVET is a fully Canadian owned and operated company with its headquarter in Ontario. We are
        a full-service veterinary distribution company range from disposable products to large
        equipment. We work exclusively with veterinarians to promote best level of care to their
        patients by providing industry leading technologies and services
      </p>
      <p>
        Our management team is group of passionate individuals who have worked in human healthcare
        industry for more than a decade. We believe that there is tremendous opportunity to enhance
        animal health by integrating many well established products and processes from human
        healthcare industry.
      </p>
      <p>
        We work in synergy with our network of highly qualified manufacturer’s to ensure that our
        product offerings not only meet but exceed current standards to ensure best patient care
      </p>
    </div>
    <h2>Vision</h2>
    <p>Be the source for veterinarians to save money.</p>
    <h2>Mission</h2>
    <p>
      To provide innovative, integrated health care products and services; and to be trusted source
      to our customers while optimize their practice management efficiency and profitability.
    </p>
  </div>
);

export default About;
