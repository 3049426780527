/* eslint-disable no-undef */
import { createClient } from "contentful";

const client = createClient({
  space: "oddxfqh4yexd",
  accessToken: process.env.REACT_APP_ONVET_CONTENTFUL_API,
});

export const getHomeSliders = async () => {
  const entries = await client.getEntries({
    content_type: "homeSlider",
  });

  return entries.items.map((item) => {
    return {
      label: item.fields.label,
      imageUrl: item.fields.image.fields.file.url,
      id: item.sys.id,
    };
  });
};

export const getPromotions = async () => {
  const entries = await client.getEntries({
    content_type: "promotions",
    order: "sys.createdAt",
  });

  return entries.items.map((item) => {
    return {
      title: item.fields.title,
      imageUrl: item.fields.image.fields.file.url,
      id: item.sys.id,
    };
  });
};

export const getProductCategories = async () => {
  const entries = await client.getEntries({
    content_type: "productCategory",
  });

  const productCategories = entries.items.map((item) => {
    const { name, image, id } = item.fields;
    if (id === "more") return null;
    return {
      name,
      imageUrl: image.fields.file.url,
      id,
    };
  });

  const sortedCategories = productCategories
    .filter((c) => !!c)
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });

  const moreCategory = entries.items.filter((item) => item.fields.id === "more");

  return [
    ...sortedCategories,
    ...[
      {
        name: moreCategory[0].fields.name,
        id: moreCategory[0].fields.id,
        imageUrl: moreCategory[0].fields.image.fields.file.url,
      },
    ],
  ];
};
