import React, { useEffect } from "react";

import "../css/privacy.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";

export const TermOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container terms">
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Terms of Service</h1>
      <h2>About these Terms of Service</h2>
      <p>
        These Terms of Service apply to websites, applications, products, and services of ONVET
        MEDICAL CANADA INC. and affiliated entities. By using our websites, applications, products
        or other services (“Services”), you signify your acceptance of these Terms of Service.
        Individual Services may be further subject to additional terms and conditions.
      </p>
      <h3>1. Privacy.</h3>
      <p>Please review our Privacy Policy, which also governs your use of ONVET the Services.</p>
      <h3>2. Changes and Updates to Terms of Service.</h3>
      <p>
        Please read these Terms of Service carefully. We review and may revise these Terms of
        Service from time to time as we add new features and services, as laws change, and as
        industry best practices evolve. We indicate the date the most recent changes were published
        on this page. If we update these Terms of Service with significant changes, we will let you
        know, for example by placing a note on our website. Please review periodically.
      </p>
      <h3>3. Your Use of Content.</h3>
      <p>
        The content available on or through use of websites, downloading any application from our
        web sites, or using our products or services, including but not limited to data, text,
        articles, software, images, graphics, downloads, icons, logos, photographs, audio and video,
        links and references, software, and other materials (collectively “Content”) is provided for
        informational purposes only. THE CONTENT IS NOT INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL
        VETERINARY ADVICE, DIAGNOSIS, OR TREATMENT. WE NOT RECOMMEND OR ENDORSE ANY SPECIFIC
        VETERINARIANS, PRODUCTS, TESTS, PROCEDURES, OPINIONS OR OTHER CONTENT THAT MAY BE MENTIONED
        ON THE SITE. ALWAYS SEEK THE ADVICE OF A QUALIFIED VETERINARY PROFESSIONAL WITH ANY
        QUESTIONS YOU MAY HAVE REGARDING AN ANIMAL&apos;S MEDICAL CONDITION. DO NOT DISREGARD
        PROFESSIONAL VETERINARY ADVICE OR DELAY IN SEEKING IT BECAUSE OF CONTENT ON THE SITE.
        RELIANCE ON ANY CONTENT ON THIS SITE IS SOLELY AT YOUR OWN RISK.
      </p>
      <h3>4. Products, Services and Content Provided.</h3>
      <p>
        All Services and Content are subject to US and international intellectual property laws. You
        acknowledge and agree that the Content and Services are owned by us, our suppliers and/or
        licensors, as applicable, and are protected by intellectual property laws. We reserve the
        right to change, modify, or delete the content at any time. You may not distribute, modify,
        transmit or publicly display the content without the prior written consent of ONVET or its
        licensors or suppliers, as applicable. You shall take all reasonable steps to prevent any
        unauthorized reproduction and/or other use of the Content and will advise us promptly in the
        event you become aware of any such unauthorized use(s). We reserve the right to terminate or
        suspend access to Services and Content, in whole or in part, without notice.
      </p>
      <h3>5. Your Account.</h3>
      <p>
        In order to use some of the features or functionality offered through our Services, you may
        need to create an account and have a valid payment method associated with it. You are
        responsible for providing truthful and accurate information to create an account, and for
        maintaining the confidentiality of passwords to your account. If you are under 18, you may
        use the Content and Services only with the involvement of a parent or legal guardian. We
        reserve the right to terminate your account, refuse registration of passwords, refuse
        service, or terminate your rights under these Terms of Service, or cancel orders in our
        exclusive discretion. All information provided to us is subject to the ONVET Privacy Policy.
        If you have questions about your account, or have reason to believe that your account with
        us is no longer secure, you agree to immediately notify us at
        <a className="inline-link" href="mailto:support@covetrus.com">
          info@onvet.ca
        </a>
      </p>
      <h3>6. Third Party Links.</h3>
      <p>
        The Site may contain links or other references to web sites and/or services operated by
        third parties including, without limitation, social media and social networking services
        (the “Third Party Sites”). Your use of any Third Party Sites is subject to the privacy
        policies and terms of use established by the specific Third Party Site, and ONVET disclaims
        all liability associated therewith. The presence of any link or reference to a Third Party
        Site does not indicate any approval or endorsement by ONVET. Further, descriptions of, or
        references to, third party products, services or publications within ONVET Services do not
        imply endorsement of, and ONVET is not responsible for and disclaims any endorsement of, any
        such product, service or publication. To personalize your experience with our Services, you
        may have the ability to log in via certain Third Party Sites. Third Party Sites will provide
        us with access to certain information that you have provided to such Third Party Sites, and
        we will use, store and disclose such information in accordance with our Privacy Policy.
        However, please remember that the manner in which Third Party Sites use, store and disclose
        your information is governed solely by the policies of such third parties, and we shall not
        have any liability or responsibility for the privacy practices or other actions of any Third
        Party Sites that may be enabled within our Site.
      </p>
      <h3>7. Code of Conduct.</h3>
      <p>
        You agree to use our Services solely in compliance with these Terms of Service and
        applicable law. Without limiting the generality of the foregoing, you agree not to do any of
        the following: spamming; phishing; transmission of unlawful, harmful, objectionable, or in
        appropriate content; transmission of trade secrets; transmission of advertisements,
        solicitation, or unsolicited commercial or harmful communication; restriction of or
        interference with any other visitor from using the Services; directly or indirectly
        expressing or implying that statements you make are endorsed by us; uploading any content
        containing any virus, worm, unauthorized file, script, or program designed to damage or
        allow unauthorized use of or access to the Services; removing any copyright, trademark or
        proprietary rights notices; framing or mirroring any portion of the Site; use of any spider,
        site search/archival application, bot, or other manual or automatic device or process to
        retrieve, index, “data mine” or in any way reproduce or circumvent the navigational
        structure or presentation of the Site or its contents; forging or otherwise manipulating
        headers or identifiers in order to disguise the origin of any email; use false email
        addresses, or misleading content to impersonate another person; or harvest or collect
        information about the Site visitors or members without their express written consent.
      </p>
      <h3>8. Purchases.</h3>
      <p>
        <strong>8.1.</strong> If you wish to purchase any products through our websites or
        applications, you will be asked by us or the third-party provider of the Services to supply
        certain information applicable to your purchase, including, without limitation, credit card
        details and shipping information. You understand that any such information will be treated
        by us in the manner described in our Privacy Policy. You agree that all information that you
        provide to us or such third- party provider will be true, accurate, current and complete.
      </p>
      <p>
        <strong>8.2.</strong> You agree to pay all fees and charges incurred by you or any users of
        your account and credit card (or other applicable payment mechanism) at the price(s) in
        effect when such charges are incurred. You will also be responsible for paying any
        applicable taxes relating to your purchases. All amounts appearing on websites and
        applications are quoted in local currency. Payments must be made using a valid payment card.
        Debit or credit card payments are processed using a third-party payment processor.
      </p>
      <p>
        <strong>8.3.</strong> Despite our efforts, products listed on our websites, applications, or
        in our printed formularies may be mispriced. If we discover a mispricing, we will do one of
        the following: (a) if an item&apos;s correct price is lower than the stated price, we will
        charge the lower amount and ship you the item; or (b) if an item&apos;s correct price is
        higher than the stated price, we will, at our discretion, either contact you for
        instructions before shipping or cancel your order and notify you of such cancellation.
      </p>
      <p>
        <strong>8.4.</strong> Descriptions or images of, or references to, products do not imply our
        endorsement of such products. We reserve the right, without prior notification, to change
        such descriptions or references, to change the prices, fees and charges associated with any
        Service, to limit the order quantity on any Service, to reject, correct, cancel or terminate
        any order, and/or to refuse to provide any Services to you. Verification of information
        applicable to a purchase may be required prior to our acceptance of any order. Price and
        availability of any Services are subject to change without notice.
      </p>
      <p>
        <strong>8.5.</strong> Once you have completed your purchase, you will receive an e-mail
        confirming your order. Please keep this e-mail as it is your sales receipt and order
        confirmation for products ordered, and will include your order number. All order fulfillment
        e-mails will contain a contact e-mail address which you should use to contact your
        veterinarian or our customer support if you require additional information about orders,
        pricing, products, services, accounts or passwords. The address for support is:
        <a className="inline-link" href="mailto:support@covetrus.com">
          info@onvet.ca
        </a>
      </p>
      <p>
        <strong>8.6.</strong> Subject to applicable law, shipping, return and exchange of any
        Service purchased from the Site will be governed by our Shipping and Return policies. We
        reserve the right, without prior notification, to change our Shipping and Return policies at
        any time. We attempt to be as accurate as possible in the description of products on the
        Site. However, we do not warrant that product descriptions or other content is wholly
        accurate, complete, reliable, current or error-free. If a product offered on the Site is not
        as described, your sole remedy is to return it in unused condition.
        <strong>
          PLEASE NOTE THAT PRESCRIPTION MEDICATIONS MAY NOT BE RETURNED, AND ALL SALES ARE FINAL PER
          STATE AND FEDERAL LAWS.
        </strong>
      </p>
      <h3>9. Intellectual Property.</h3>
      <p>
        All content included in or made available through our Services, including copyrights,
        trademarks, logos, and service marks displayed on websites, communications and applications,
        are the intellectual property of ONVET, our licensors and suppliers, and others. Nothing
        contained in our Services should be construed as granting, by implication, estoppel or
        otherwise, any license or right to use any intellectual property without the express written
        permission of ONVET, our licensors or suppliers, or the third-party owner of any such
        intellectual property and use of the intellectual property is expressly prohibited. ONVET
        trademarks may not be used in connection with any product or service that is not ours, in
        any manner that is likely to cause confusion among customers, or in any manner that
        disparages ONVET, its licensors, or its suppliers. All brands are holders of their
        respective trademarks.
      </p>
      <h3>10. Rules for Polls, Surveys, Sweepstakes, Contests and Games.</h3>
      <p>
        Any polls, surveys, sweepstakes, contests or games (collectively, the “Contests”) that are
        accessible through our websites are governed by these Terms of Service, to the extent
        applicable, and by any such additional rules that are made available with the Contest. By
        entering or participating in the Contests, you signify your agreement and acceptance of
        these Terms of Service and such applicable rules. Our Privacy Policy governs any information
        you submit in connection with such Contests.
      </p>
      <h3>11. Ideas and Postings.</h3>
      <p>
        You may submit or post creative ideas, suggestions, feedback or materials, or other
        communications (collectively, “Ideas”) to us through the Site or by direct communication, so
        long as such submissions are not illegal, threatening, obscene, defamatory, invasive of
        privacy, infringe the rights of third parties, or are otherwise objectionable. By
        submitting, sending, posting, displaying, performing and/or otherwise distributing Ideas to
        ONVET, you hereby grant us and our designees a worldwide, nonexclusive, sub-licensable
        (through multiple tiers), assignable, royalty-free, perpetual, irrevocable right to use,
        reproduce, distribute (through multiple tiers), create derivative works of, publicly
        perform, publicly display, digitally perform, make, have made, sell, offer for sale and
        import such Ideas in any media now known or hereafter developed, for any purpose whatsoever,
        commercial or otherwise, without compensation and without an obligation to report to the
        provider of the Ideas, and waive all moral rights (including any rights to attribution) that
        you may have in such Ideas. You further agree that we and our designees shall exclusively
        own all documents, works and other materials that incorporate all or part of any Idea(s). If
        you do submit or post Ideas, you acknowledge and agree that other persons, including ONVET
        employees and contractors along with others who have or will make Ideas, may have submitted
        or may submit in the future Ideas that are similar to the Ideas submitted by you. You
        acknowledge and agree that you will not receive any compensation because of ONVET&apos;s use
        of other similar Ideas. You agree that no confidential or fiduciary relationship is
        established between you and ONVET as a result of your submission of the Idea. ONVET has no
        obligation to use or distribute your Ideas. You also acknowledge and agree that, with
        respect to any portion of any of your Ideas that are not protectable, submission of the
        Ideas shall not be deemed to place ONVET in any different position than members of the
        general public.
      </p>
      <h3>12. Termination.</h3>
      <p>
        These Terms of Service shall remain effective until terminated in accordance with its terms.
        Either you or ONVET may terminate these Terms of Service immediately upon notice to the
        other party. In addition, we reserve the right to immediately terminate these Terms of
        Service, and/or your access to and use of the Site, or any portion thereof, at any time and
        for any reason, with or without cause. Upon termination of these Terms of Service by either
        party, your right to use the Services shall immediately cease, and you shall destroy all
        copies of any Content that you have obtained from ONVET, whether made under the terms of
        these Terms of Service or otherwise. The following sections of these Terms of Service shall
        survive the termination of these Terms of Service: 2, 3, 6, 7, 8, 9, 12, 13, 14, 15, 16, 17
        and 18.
      </p>
      <h3>13. Disclaimers.</h3>
      <p>
        <strong>13.1 As Is Basis.</strong> TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW:
        (A) WEBSITES AND APPLICATIONS ARE PROVIDED ON A STRICTLY “AS IS” BASIS AND WITHOUT
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED; AND (B) ONVET AND ITS AFFILIATES, AGENTS,
        SERVICE PROVIDERS, EMPLOYEES, OFFICERS, DIRECTORS, CONSULTANTS, REPRESENTATIVES, LICENSORS,
        SUPPLIERS, ADVERTISERS, SPONSORS, SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE
        “REPRESENTATIVES”) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
        LIMITATION, IMPLIED WARRANTIES OF TITLE, NONINFRINGEMENT, ACCURACY, COMPLETENESS,
        MERCHANTABILITY, QUALITY, AND FITNESS FOR A PARTICULAR PURPOSE, ANY WARRANTIES THAT MAY
        ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND ANY WARRANTIES
        THAT WEBSITES AND APPLICATIONS ARE CURRENT AND/OR UP-TO-DATE. ONVET AND THE REPRESENTATIVES
        DO NOT WARRANT THAT THE WEBSITES AND APPLICATIONS, NOR YOUR USE OF THE WEBSITES AND
        APPLICATIONS, WILL BE COMPLETE, ACCURATE, CURRENT, RELIABLE, UNINTERRUPTED, ERROR-FREE OR
        SECURE, NOR THAT DEFECTS WILL BE CORRECTED, NOR THAT THE WEBSITES AND APPLICATIONS ARE FREE
        OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR
        OBTAINING AND MAINTAINING ALL TELEPHONE, COMPUTER HARDWARE AND OTHER EQUIPMENT NEEDED TO
        ACCESS AND USE THE WEBSITES AND APPLICATIONS, AND ALL CHARGES RELATED THERETO. YOU ASSUME
        ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE WEBSITES AND APPLICATIONS AND YOUR RELIANCE
        THEREON. NO OPINION, ADVICE OR STATEMENT OF ONVET OR ANY OF REPRESENTATIVES, WHETHER MADE ON
        OR IN CONNECTION WITH THE WEBSITES AND APPLICATIONS SHALL CREATE ANY WARRANTY.
      </p>
      <p>
        <strong>13.2 No Implied Warranties.</strong> Some states do not allow the disclaimer of
        implied warranties of merchantability and fitness for a particular purpose, so the above
        disclaimers or exclusions may not apply to you. In the event that applicable law imposes
        implied warranties on websites and applications, notwithstanding the foregoing, such implied
        warranties shall not have a duration greater than one year from the relevant purchase or
        access date; shall terminate automatically at the end of such period; and shall be
        disclaimed and excluded to the fullest extent permitted by law. You may also have other
        rights under applicable law which vary from state to state.
      </p>
      <p>
        <strong>13.3 User Identity.</strong> EXCEPT IN COMPLIANCE WITH APPLICABLE STATE AND FEDERAL
        LAWS, ONVET AND THE REPRESENTATIVES ARE UNDER NO OBLIGATION TO VERIFY THE IDENTITY OF USERS
        OF ITS WEBSITES AND APPLICATIONS. ONVET AND THE REPRESENTATIVES DO NOT CONTROL THE MATERIALS
        POSTED OR SUBMITTED TO WEBSITES OR APPLICATIONS BY PERSONS OTHER THAN THEMSELVES AND DO NOT
        MONITOR, SCREEN, OR EDIT THOSE MATERIALS FOR COMPLIANCE WITH APPLICABLE LAWS OR THESE TERMS
        OF SERVICE. YOU MAY FIND SOME OF THE MATERIALS POSTED BY OTHER USERS TO BE OFFENSIVE,
        HARMFUL, INACCURATE OR DECEPTIVE. YOU SHOULD USE CAUTION AND COMMON SENSE WHEN USING
        WEBSITES AND APPLICATIONS.
      </p>
      <p>
        <strong>13.4 Risk of Commercial Transaction.</strong> THROUGH YOUR USE OF THE WEBSITES AND
        APPLICATIONS, YOU MAY HAVE THE OPPORTUNITY TO ENGAGE IN COMMERCIAL TRANSACTIONS WITH THIRD
        PARTIES. ALL SUCH TRANSACTIONS ARE AT YOUR OWN RISK. ONVET AND ITS REPRESENTATIVES ARE NOT
        PARTIES TO ANY SUCH TRANSACTIONS AND DISCLAIM ANY AND ALL LIABILITY REGARDING ALL SUCH
        TRANSACTIONS.
      </p>
      <p>
        <strong>13.5. Competition Animals.</strong> ONVET AND ITS REPRESENTATIVES EXPRESSLY DISCLAIM
        ALL REPRESENTATIONS AND WARRANTIES IN REGARD TO THE SUITABILITY OR USE OF ANY PRODUCT FOR
        ANIMALS INVOLVED IN COMPETITION, INCLUDING AUTHORIZATION OR APPROVAL BY THE GOVERNING BODY
        FOR THE SPORT OR COMPETITION IN WHICH YOUR ANIMAL IS INVOLVED. ONVET&apos; LIABILITY TO YOU
        FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT
        LIMITATION, NEGLIGENCE) OR OTHERWISE ARISING OUT OF OR RELATING IN ANY MANNER TO THE USE OF
        ANY PRODUCT FOR AN ANIMAL ENGAGED IN ANY COMPETITION SHALL BE THE TOTAL AMOUNT PAID BY YOU
        FOR THE PRODUCT.
      </p>
      <h3>14. Limitation of Liability.</h3>
      <p>
        NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY OF ANY KIND, NEITHER
        ONVET NOR ANY OF ITS REPRESENTATIVES ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES OR LIABILITIES UNDER ANY
        CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY
        MANNER TO THE CONTENT, SERVICES AND/OR ANY LINKED WEBSITE OR APPLICATION, WHETHER OR NOT WE
        HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES OR LIABILITIES. YOUR SOLE REMEDY WITH
        RESPECT TO WEBSITES AND APPLICATIONS AND/OR ANY THIRD PARTY SITE IS TO STOP USING THE ONVET
        WEBSITES AND APPLICATIONS OR THE APPLICABLE THIRD PARTY SITE, AS APPLICABLE. ONVET&apos;S
        LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT
        (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE ARISING OUT OF OR RELATING IN ANY
        MANNER TO THE WEBSITES AND APPLICATIONS SHALL BE THE TOTAL AMOUNT PAID BY YOU FOR USE OF THE
        WEBSITES AND APPLICATIONS. Some states do not allow the exclusion or limitation of
        incidental or consequential damages, so the above limitation or exclusion may not apply to
        you and in such case in no event shall NO EVENT SHALL ONVET&apos; TOTAL LIABILITY TO YOU FOR
        ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED ONE HUNDRED DOLLARS ($100).
      </p>
      <h3>15. Indemnification.</h3>
      <p>
        You agree to fully indemnify, defend and hold ONVET and its Representatives from and against
        any and all claims, damages, losses, costs (including reasonable attorneys&apos; fees) and
        other expenses that arise directly or indirectly out of or from (a) your breach of these
        Terms of Service, (b) any allegation that any Ideas or other materials you submit to us or
        transmit to a ONVET website or application infringe or otherwise violate the copyright,
        patent, trademark, trade secret or other intellectual property or other rights of any third
        party, and/or (c) your activities in connection with the Site.
      </p>
      <h3>16. Jurisdiction.</h3>
      <p>
        ONVET websites and applications are administered by ONVET from its offices in ONTARIO,
        CANADA. ONVET makes no representation that the Site is appropriate or available for use
        outside the CANADA, and access to the Site from jurisdictions in which the contents of the
        Site are illegal is prohibited. You may not use, export or re-export the Site or any copy or
        adaptation thereof, in violation of any applicable laws or regulations, including without
        limitation Canadian export laws and regulations. If you choose to access this Site from
        outside the United States, you do so on your own initiative and are responsible for
        compliance with applicable local laws.
      </p>
      <p>
        These Terms of Service shall be governed by the laws of the State of Maine without regard to
        its conflict of law provisions. With respect to any disputes or claims not subject to
        arbitration, as set forth above, you and ONVET agree to submit to the personal and exclusive
        jurisdiction of the state and federal courts located in Portland, Maine. EACH OF THE PARTIES
        HEREBY KNOWINGLY, VOLUNTARILY AND INTENTIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY
        JURY IN RESPECT OF ANY LITIGATION (INCLUDING BUT NOT LIMITED TO ANY CLAIMS, COUNTERCLAIMS,
        CROSS- CLAIMS, OR THIRD PARTY CLAIMS) ARISING OUT OF, UNDER OR IN CONNECTION WITH THESE
        TERMS OF SERVICE. FURTHER, EACH PARTY HERETO CERTIFIES THAT NO REPRESENTATIVE OR AGENT OF
        EITHER PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT SUCH PARTY WOULD NOT IN THE EVENT
        OF SUCH LITIGATION, SEEK TO ENFORCE THIS WAIVER OF RIGHT TO JURY TRIAL PROVISION. EACH OF
        THE PARTIES ACKNOWLEDGES THAT THIS SECTION IS A MATERIAL INDUCEMENT FOR THE OTHER PARTY
        ENTERING INTO these Terms of Service. The failure of ONVET to exercise or enforce any right
        or provision of these Terms of Service shall not constitute a waiver of such right or
        provision. If any provision of these Terms of Service is found by a court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to
        give effect to the parties&apos; intentions as reflected in the provision, and the other
        provisions of these Terms of Service remain in full force and effect. You agree that
        regardless of any statute or law to the contrary, any claim or cause of action arising out
        of or related to use of the Site or these Terms of Service must be filed within one (1) year
        after such claim or cause of action arose or be forever barred. A printed version of these
        Terms of Service and of any notice given in electronic form shall be admissible in judicial
        or administrative proceedings based upon or relating to these Terms of Service to the same
        extent and subject to the same conditions as other business documents and records originally
        generated and maintained in printed form. The section titles in these Terms of Service are
        for convenience only and have no legal or contractual effect. Notices to you may be made via
        either email or regular mail. The Site may also provide notices to you of changes to these
        Terms of Service or other matters by displaying notices or links to notices generally on the
        Site. You hereby acknowledge that you have carefully read all of the terms and conditions of
        the ONVET Privacy Policy and agree to all such terms and conditions.
      </p>
      <h3>17. Entire Agreement.</h3>
      <p>
        These Terms of Service constitutes the entire agreement between you and ONVET and governs
        your use of the Site, superseding any prior agreements between you and ONVET with respect to
        the websites and applications.
      </p>
      <h3>18. Severability.</h3>
      <p>
        If any provision of these Terms of Service is for any reason deemed invalid, unlawful, void
        or unenforceable by a court or arbitrator of competent jurisdiction, then that provision
        will be deemed severable from these Terms of Service, and the invalidity of the provision
        will not affect the validity or enforceability of the remainder of these Terms of Service
        (which will remain in full force and effect).
      </p>
      <h3>19. Copyright Infringement Claims.</h3>
      <p>
        If you believe that your work has been copied in a manner or type that constitutes
        infringement of your copyright, please submit a complaint to
        <a className="inline-link" href="mailto:legal@covetrus.com">
          info@onvet.ca
        </a>
        Attn: Legal Department. Your claim should include ALL of the following information:
      </p>
      <ul>
        <li>A description of the copyrighted work you believe has been infringed upon;</li>
        <li>
          A description of where the material that you claim is infringing is located on this Site;
        </li>
        <li>Your email address, phone number, and physical address;</li>
        <li>
          A statement by you that you have a good-faith belief that the alleged use on this Site is
          not authorized by the copyright owner, an agent for the copyright owner, or under the
          copyright laws of the United States;
        </li>
        <li>
          A statement by you, made under the penalty of perjury, that the information in your notice
          is accurate;
        </li>
        <li>
          A statement by you, made under the penalty of perjury, that you are the copyright owner,
          or are authorized to act on the copyright owner&apos;s behalf; and
        </li>
        <li>
          A physical signature of the copyright owner or person authorized to act on behalf of the
          copyright owner.
        </li>
      </ul>
      <h3>20. How to contact us with questions</h3>
      <p>
        If you have any questions about these Terms of Service, please contact us at
        <a className="inline-link" href="mailto:info@onvet.ca">
          info@onvet.ca
        </a>
      </p>
    </div>
  );
};
