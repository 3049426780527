/* eslint-disable no-undef */
import React, { useState } from "react";
// import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { Route } from "wouter";

import Header from "./Header";
import About from "./pages/About";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Promotions from "./pages/Promotions";
import Footer from "./Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { TermOfService } from "./components/TermOfService";
import { NewCustomerForm } from "./components/NewCustomerForm";
import { ReturnForm } from "./components/ReturnForm";
import { RepairForm } from "./components/RepairForm";

import "./css/App.scss";

function App() {
  const [authenticated, setIsAuth] = useState(true);

  const auth = (e) => {
    if (e.target.value.trim() === process.env.REACT_APP_ACCESS_KEY) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  };

  return (
    <div
      className="App"
      style={{
        position: "relative",
      }}>
      {authenticated ? (
        <>
          <Header></Header>
          <div className="main-view">
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/product">
              <Products></Products>
            </Route>
            <Route exact path="/promotions">
              <Promotions></Promotions>
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/privacy">
              <PrivacyPolicy></PrivacyPolicy>
            </Route>
            <Route exact path="/terms">
              <TermOfService></TermOfService>
            </Route>
            <Route exact path="/new-customer">
              <NewCustomerForm></NewCustomerForm>
            </Route>
            <Route exact path="/return">
              <ReturnForm></ReturnForm>
            </Route>
            <Route exact path="/repair">
              <RepairForm></RepairForm>
            </Route>
          </div>
          <Footer></Footer>
        </>
      ) : (
        <input onChange={auth} />
      )}
    </div>
  );
}

export default App;
