import React from "react";

import "../css/Promotions.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";
import { useQuery } from "react-query";
import { getPromotions } from "../utils/cms";

const Promotion = () => {
  const { data } = useQuery("promotions", getPromotions, {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="container promotions">
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Promotions</h1>
      <div className="promotions-grid">
        {data &&
          data.map((promotion) => (
            <button key={promotion.id} className="promotion-card">
              <img src={`https:${promotion.imageUrl}`} className="promotion-card-image"></img>
              <h2>{promotion.title}</h2>
            </button>
          ))}
      </div>
    </div>
  );
};

export default Promotion;
