import React, { useState } from "react";
import { Link } from "wouter";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import "./css/Header.scss";
import Logo from "./images/logo.jpeg";
import { useLocation } from "wouter";
import classNames from "classnames";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const [location] = useLocation();

  return (
    <Navbar expanded={expanded} sticky="top" expand="lg" className="header">
      <Container className="header-container">
        <Navbar.Brand as={Link} to="/">
          <div className="logo-container">
            <img className="logo" src={Logo} alt="Onvet"></img>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : true)}
          aria-controls="onvet-navbar"
        />
        <Navbar.Collapse id="onvet-navbar" className="justify-content-end">
          <Nav>
            <Link
              className={classNames("nav-link", {
                "selected-nav": location === "/about",
              })}
              onClick={() => setExpanded(false)}
              to="/about">
              About Us
            </Link>
            <Link
              className={classNames("nav-link", {
                "selected-nav": location === "/product",
              })}
              onClick={() => setExpanded(false)}
              to="/product">
              Products
            </Link>
            <Link
              className={classNames("nav-link", {
                "selected-nav": location === "/promotions",
              })}
              onClick={() => setExpanded(false)}
              to="/promotions">
              Promotions
            </Link>
            <NavDropdown title="Resources">
              <NavDropdown.Item as={Link} to="/new-customer">
                New Customer Application
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/return">
                Return/Exchange
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/repair">
                Service Repair
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">
                Manual Download
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">
                Vendor Catalogue
              </NavDropdown.Item>
            </NavDropdown>
            <Link
              className={classNames("nav-link", {
                "selected-nav": location === "/contact",
              })}
              onClick={() => setExpanded(false)}
              to="/contact">
              Contact
            </Link>
            <div className="form-input">
              <input type="text" className="text-input" placeholder="Search Products"></input>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
