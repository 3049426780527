import { toPng } from "html-to-image";
import React, { useState, useRef } from "react";
import { Col, Form, Row, Alert, Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import "../css/newCustomerForm.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";
import { GoogleCaptcha } from "../components/GoogleCaptcha";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const BUSINESS_TYPE = {
  smallAnimal: "Small Animal",
  zoo: "Zoo/Aquarium",
  doctor: "Doctor of Medicine",
  shelter: "Humane Society/Shelter/Rescue",

  largeAnimal: "Large Animal",
  wholesaler: "Veterinary Distributor/Wholesaler",
  researcher: "Researcher",
  corporation: "Corporation",

  mixedMostlySmall: "Mixed, Mostly Small",
  pharmacy: "Pharmacy/Pharmacist",
  teaching: "Teaching/Research Institution",
  military: "Military",

  mixedMostlyLarge: "Mixed, Mostly Large",
  onlineStore: "Retail Only/Online Store",
  personal: "Personal Animal Use",
  federal: "Federal Government",

  equine: "Equine",
  pet: "Pet Service/Board/Groomer",
  state: "State/Local Government",
  mobileVet: "Mobile Veterinary",
};

const APPLICABLE_BUSINESS_TYPES = {
  corporation: "Corporation",
  government: "Government Entity",
  partnership: "Partnership",
  notForProfit: "Not for Profit",
  solePri: "Sole Proprietorship",
};

const PAY_SCHEDULE = {
  perOrder: "Per Order(MCV)",
  monthly: "Monthly balance on 10th",
};

const renderCreditCard = (
  payschedule,
  handlePayscheduleChange,
  handleShow,
  signatureUrl,
  register
) => {
  return (
    <div className="credit-card-inputs">
      <h2>Automatic Credit Card Agreement</h2>
      <Alert variant="info">
        I (we) hereby authorize ONVET to accept my credit card as a method of payment for my
        account. Continued failure to obtain authorization for my charges may result in removal from
        the credit card payment plan. By signing below, I accept the terms and conditions of this
        agreement and authorize ONVET to debit my card:
      </Alert>
      <div className="pay-schedule">
        <div>
          <p>Please select one:</p>
        </div>
        {Object.keys(PAY_SCHEDULE).map((key) => {
          return (
            <Form.Check
              className="pay-schedule-radio"
              type="radio"
              label={PAY_SCHEDULE[key]}
              checked={payschedule === key}
              id={`payschedule-${key}`}
              key={key}
              onChange={() => handlePayscheduleChange(key)}
            />
          );
        })}
      </div>
      <div className="credit-card">
        <div className="form-input">
          <label className="form-required" htmlFor="creditCard">
            Card Number (ONVET accepts Visa, MasterCard)
          </label>
          <input
            {...register("creditCard", { required: true })}
            className="text-input"
            id="creditCard"
          />
        </div>
        <div className="form-input">
          <label className="form-required" htmlFor="expiration">
            Expiration Date
          </label>
          <input
            {...register("expiration", { required: true })}
            className="text-input"
            id="expiration"
          />
        </div>
      </div>
      <div
        className="form-input"
        style={{
          margin: "20px 0",
        }}>
        <label className="form-required" htmlFor="cardHolderName">
          Cardholder’s Name
        </label>
        <input
          {...register("cardHolderName", { required: true })}
          className="text-input"
          id="cardHolderName"
        />
      </div>
      <div
        className="form-input"
        style={{
          margin: "20px 0",
        }}>
        <label className="form-required" htmlFor="billingAddress">
          Card Billing Address
        </label>
        <input
          {...register("billingAddress", { required: true })}
          className="text-input"
          id="billingAddress"
        />
      </div>

      <div className="locations">
        <div className="form-input">
          <label className="form-required" htmlFor="creditCardCity">
            City
          </label>
          <input
            {...register("creditCardCity", { required: true })}
            className="text-input"
            id="creditCardCity"
            type="text"
          />
        </div>
        <div className="form-input">
          <label className="form-required" htmlFor="creditCardProvince">
            Province
          </label>
          <input
            {...register("creditCardProvince", { required: true })}
            className="text-input"
            id="creditCardProvince"
            type="text"
          />
        </div>
        <div className="form-input">
          <label className="form-required" htmlFor="creditCardZip">
            Postal Code
          </label>
          <input
            {...register("creditCardZip", { required: true })}
            className="text-input"
            id="creditCardZip"
            type="text"
          />
        </div>
        <div className="form-input">
          <label className="form-required" htmlFor="creditCardCountry">
            Country
          </label>
          <input
            {...register("creditCardCountry", { required: true })}
            className="text-input"
            id="creditCardCountry"
            type="text"
          />
        </div>
      </div>
      <div className="signatures">
        <Form.Group className="signature-input" controlId="new-customer-owner">
          <Form.Label className="form-required">SIGNATURE (Required for this agreement)</Form.Label>
          {signatureUrl ? (
            <img
              style={{
                width: "300px",
              }}
              src={signatureUrl}
              alt="customer signature"></img>
          ) : (
            <Button onClick={handleShow}>Add Signature</Button>
          )}
        </Form.Group>
        <div className="form-input">
          <label className="form-required" htmlFor="signatureDate">
            Date of Signature
          </label>
          <input
            {...register("signatureDate", { required: true })}
            className="text-input"
            id="signatureDate"
            type="text"
            placeholder="YYYY-MM-DD"
          />
        </div>
      </div>
    </div>
  );
};

export const NewCustomerForm = () => {
  const [payschedule, setPaySchedule] = useState("perOrder");
  const [step, setStep] = useState(1);
  const [signatureUrl, setSignatureURL] = useState(null);
  const [firstPageImage, setFirstPageImage] = useState(null);
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [recaptchaValid, setRecaptchaValid] = useState(false);

  const handlePayscheduleChange = (paySchedule) => {
    setPaySchedule(paySchedule);
  };
  const ref = useRef(null);
  const sigRef = useRef({});

  const handleNextClicked = async () => {
    if (ref.current === null) {
      return;
    }

    const blob = await toPng(ref.current, {
      cacheBust: true,
      backgroundColor: "white",
      pixelRatio: 1,
    });

    setFirstPageImage(blob);
    setStep(2);
  };

  const submitCustomerForm = async () => {
    if (!firstPageImage || !signatureUrl) return;

    if (ref.current === null) {
      return;
    }

    try {
      setIsSubmitting(true);
      const blob = await toPng(ref.current, {
        cacheBust: true,
        backgroundColor: "white",
        pixelRatio: 1,
      });

      const imageId = uuidv4();
      const imageToUpload = [
        {
          title: `new-customer-page-1-${imageId}.png`,
          base64String: firstPageImage,
        },
        {
          title: `new-customer-page-2-${imageId}.png`,
          base64String: blob,
        },
      ];

      // Make API call to upload
      const resp = await axios.post(
        "https://us-central1-onvet-c7af7.cloudfunctions.net/widgets/uploadImage",
        {
          imageData: imageToUpload,
        }
      );

      if (resp.status === 200) {
        setShowToast(true);
      }
    } catch (error) {
      console.error("Failed to submit new customer form.", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearSignature = () => sigRef.current.clear();

  const saveSignature = () => {
    const imageUrl = sigRef.current.getTrimmedCanvas().toDataURL("image/png");
    setSignatureURL(imageUrl);

    handleClose();
  };

  const { register, formState } = useForm({
    mode: "onTouched",
  });

  const { register: register2, formState: formState2 } = useForm({
    mode: "onTouched",
  });

  const enableSubmit = formState.isValid && formState2.isValid && !!signatureUrl && recaptchaValid;

  return (
    <div className="container new-customer-form">
      <Modal show={showToast} onHide={() => setShowToast(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your application has been sent successfully. We will get back to you soon.
        </Modal.Body>
      </Modal>
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>New Customer Application</h1>
      <p>
        Thank you for choosing ONVET Medical Canada Inc as your veterinary distribution partner. We
        welcome this opportunity to provide you with the products and services necessary to conduct
        your practice and manage your business. It is our mission to provide the best customer
        experience possible.
      </p>
      <p>
        Once completed you may return the application to the email address, fax number, or mail to
        the address listed at the bottom of this page. As you complete this application, should you
        have any questions, you may ask your Local sales representative or call our customer service
        at 1-888-655-0106. One of our representatives will gladly answer your questions or provide
        you with the additional information you need.
      </p>
      <p>
        Thank you for giving us this opportunity to serve you. We appreciate this expression of
        confidence and look forward to a long and mutually beneficial relationship.
      </p>
      <p>Sincerely,</p>
      <p>ONVET Customer Relation Team</p>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignatureCanvas
            ref={sigRef}
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}></SignatureCanvas>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={clearSignature}>
            Clear
          </Button>
          <Button variant="primary" onClick={saveSignature}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Form
        style={{
          padding: "20px 0",
        }}
        ref={ref}>
        {step === 1 && (
          <>
            <h2
              style={{
                marginTop: "32px",
              }}>
              Customer Information
            </h2>
            <div className="customer-info-inputs">
              <div className="form-input">
                <label className="form-required" htmlFor="legalName">
                  Name of Practice/Legal Name of Entity
                </label>
                <input
                  {...register("legalName", { required: true })}
                  className="text-input"
                  id="legalName"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="license">
                  Practitioner License #
                </label>
                <input
                  {...register("license", { required: true })}
                  className="text-input"
                  id="license"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="practiceOwnerName">
                  Name of Practice Owner (Last, First)
                </label>
                <input
                  {...register("practiceOwnerName", { required: true })}
                  className="text-input"
                  id="practiceOwnerName"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label htmlFor="primaryContact">
                  Non-licensed Primary Account Contact (if applicable)
                </label>
                <input
                  {...register("primaryContact")}
                  className="text-input"
                  id="primaryContact"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="businessPhone">
                  Business Phone
                </label>
                <input
                  {...register("businessPhone", { required: true })}
                  className="text-input"
                  id="businessPhone"
                  type="tel"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="businessFax">
                  Business Fax
                </label>
                <input
                  {...register("businessFax")}
                  className="text-input"
                  id="businessFax"
                  type="text"
                />
              </div>
            </div>
            <Row className="mb-3">
              <Col>
                <div className="form-input">
                  <label className="form-required" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    {...register("email", { required: true })}
                    className="text-input"
                    id="email"
                    type="email"
                  />
                  <p className="form-input-text-muted">
                    Please provide an email address that will be directed to persons authorized to
                    manage all aspects of your account including bill matters. This email will be
                    used to provide notifications of your online statement availability, corporate
                    communications, and offerings.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="form-input">
                  <label className="form-required" htmlFor="mailAddress">
                    Mailing/Billing Address
                  </label>
                  <input
                    {...register("mailAddress", { required: true })}
                    className="text-input"
                    id="mailAddress"
                    type="text"
                  />
                </div>
              </Col>
            </Row>
            <div className="location-inputs">
              <div className="form-input">
                <label className="form-required" htmlFor="mailAddressCity">
                  City
                </label>
                <input
                  {...register("mailAddressCity", { required: true })}
                  className="text-input"
                  id="mailAddressCity"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="mailAddressProvince">
                  Province
                </label>
                <input
                  {...register("mailAddressProvince", { required: true })}
                  className="text-input"
                  id="mailAddressProvince"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="mailAddressZip">
                  Postal Code
                </label>
                <input
                  {...register("mailAddressZip", { required: true })}
                  className="text-input"
                  id="mailAddressZip"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="mailAddressCountry">
                  Country
                </label>
                <input
                  {...register("mailAddressCountry", { required: true })}
                  className="text-input"
                  id="mailAddressCountry"
                  type="text"
                />
              </div>
            </div>
            <Row className="mb-3">
              <Col>
                <div className="form-input">
                  <label htmlFor="businessAddress">Business Shipping Address</label>
                  <input
                    {...register("businessAddress")}
                    className="text-input"
                    id="businessAddress"
                    type="text"
                  />
                  <p className="form-input-text-muted">
                    If different than billing (Must provide a physical address. No PO Box or UPS
                    Store, unless allowed by your state.)
                  </p>
                </div>
              </Col>
            </Row>
            <div className="location-inputs">
              <div className="form-input">
                <label htmlFor="businessAddressCity">City</label>
                <input
                  {...register("businessAddressCity")}
                  className="text-input"
                  id="businessAddressCity"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label htmlFor="businessAddressProvince">Province</label>
                <input
                  {...register("businessAddressProvince")}
                  className="text-input"
                  id="businessAddressProvince"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label htmlFor="businessAddressZip">Postal Code</label>
                <input
                  {...register("businessAddressZip")}
                  className="text-input"
                  id="businessAddressZip"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label htmlFor="businessAddressCountry">Country</label>
                <input
                  {...register("businessAddressCountry")}
                  className="text-input"
                  id="businessAddressCountry"
                  type="text"
                />
              </div>
            </div>

            <div className="contact-inputs">
              <div className="form-input">
                <label className="form-required" htmlFor="apContact">
                  Accounts Payable Contact
                </label>
                <input
                  {...register("apContact", { required: true })}
                  className="text-input"
                  id="apContact"
                  type="text"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="apPhone">
                  Phone Number
                </label>
                <input
                  {...register("apPhone", { required: true })}
                  className="text-input"
                  id="apPhone"
                  type="tel"
                />
              </div>
              <div className="form-input">
                <label className="form-required" htmlFor="apEmail">
                  Email Address
                </label>
                <input
                  {...register("apEmail", { required: true })}
                  className="text-input"
                  id="apEmail"
                  type="email"
                />
              </div>
            </div>
            <Row className="mb-3">
              <Col>
                <h2>Business Type (Choose all that apply)</h2>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <div className="business-type-all">
                  {Object.keys(BUSINESS_TYPE).map((key) => {
                    return (
                      <Form.Check
                        key={key}
                        type="checkbox"
                        id={`${key}`}
                        label={`${BUSINESS_TYPE[key]}`}
                        className="checkbox-label"
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <h2>Business Type (as applicable)</h2>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <div className="business-type-applicable">
                  <div className="business-type-applicable-text">
                    <p>Applicant certifies that it is doing business as:</p>
                  </div>
                  <div className="business-type-applicable-checkboxes">
                    {Object.keys(APPLICABLE_BUSINESS_TYPES).map((key) => {
                      return (
                        <Form.Check
                          key={key}
                          type="checkbox"
                          id={`${key}`}
                          label={`${APPLICABLE_BUSINESS_TYPES[key]}`}
                          className="checkbox-label"
                        />
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}

        {step === 2 &&
          renderCreditCard(
            payschedule,
            handlePayscheduleChange,
            handleShow,
            signatureUrl,
            register2
          )}
        <div className="form-button">
          {step === 1 ? (
            <Button disabled={!formState.isValid} onClick={() => handleNextClicked()}>
              Next
            </Button>
          ) : (
            <div className="second-page-submit">
              <div className="captcha">
                <GoogleCaptcha
                  onSuccess={() => setRecaptchaValid(true)}
                  onFailure={() => setRecaptchaValid(false)}
                />
              </div>
              <div className="submit-buttons">
                <Button onClick={() => setStep(1)}>Go Back</Button>
                <Button
                  disabled={!enableSubmit || isSubmitting}
                  onClick={() => submitCustomerForm()}>
                  {isSubmitting ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Submitting...</span>
                    </Spinner>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};
