import React from "react";

import "../css/privacy.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";

const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container privacy">
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>
        At ONVET, we&apos;re committed to protecting the privacy and security of your information.{" "}
      </p>
      <p>
        Whether you are a veterinary healthcare provider or a pet or horse owner, we know the
        privacy of your information is important. This policy is designed to help you understand
        what information we collect, why we collect it, how we use it, and how you can manage your
        information with us. ONVET strives to improve the health of companion animals and horses,
        and to improve the experience of the people involved along the way.
      </p>
      <p>
        For example, information you provide to us allows us to alert pet owners when their
        pet&apos;s medication is running out, to notify veterinary healthcare providers when a
        patient is overdue for a visit, or to make sure we always stock enough of the products you
        rely on us to provide. In order to do these things, we collect both personal and
        non-personal information in a few different ways.
      </p>
      <h2>Scope</h2>
      <p>
        This Policy applies to website visitors, veterinary healthcare providers, pet and horse
        owners, application and product users, and other customers of ONVET, Inc. and its affiliated
        entities and business. By using our website, downloading any application from our web site,
        or using our products or services, you signify your acceptance of this privacy policy.
      </p>
      <h2>Information you provide to us</h2>
      <p>
        We collect information at several different points as we interact with you. This includes
        when you create an account, order from the online store, chat with customer service, speak
        with your account representative, use one of our products or services, register for our
        newsletter, participate in promotions, or participate in surveys. The information we collect
        includes things like the name of a pet owner or veterinarian, a pet&apos;s name, pet health
        updates, mailing addresses, email addresses, telephone numbers, and passwords.
      </p>
      <h2>Information we collect when you use our product and services</h2>
      <p>
        When you use our products and services, we collect information about how you are using them
        and how well those products and services are working. For example, this can include usage
        data, device data, or patient information management systems (PIMS) data. We also collect
        data when you use our veterinary healthcare products, including information in user
        accounts. Each of our veterinary healthcare products is subject to your consent when you
        subscribe to the product, and each product has its own terms and conditions. You can
        withdraw your consent at any time by unsubscribing from the product and discontinuing use.
      </p>
      <h2>What we do with data — Veterinary healthcare providers</h2>
      <p>
        For veterinary healthcare providers accessing our website, platform, PIMS systems, or other
        products, or downloading apps from our websites, we collect data in user accounts, and we
        may access, use and archive information, including to ensure that digital prescriptions and
        patient records are always consistent, and that you have a complete view of your patient
        records. We may share your clinic&apos;s name, sales volume, and products sold with
        manufacturers and other industry partners in order to comply with contractual obligations,
        such as providing information to our manufacturers as an authorized distributor of a product
        subject to drug supply chain security and transparency reporting requirements, or for
        manufacturer marketing purposes. Our websites, platforms, products, and apps may also each
        be subject to additional terms and conditions. In addition:
      </p>
      <p>
        <ul>
          <li>
            We do not share personally identifiable information from your customers (including pet
            owner names, addresses, emails, telephone numbers, or health information on an
            individually identifiable animal), with any other veterinary healthcare providers,
            corporate veterinary groups, industry partners, manufacturers, or anyone else, without
            your express consent to share that information.
          </li>
          <li>
            We may share your own data with you, as a veterinary healthcare provider, so you can use
            that information to deliver the best possible care to your patients.
          </li>
          <li>
            We share data analytics, based on de-identified information, with manufacturers and
            industry partners. For example, this may include data such as total sales of a
            medication in a certain region or overall compliance percentages of animals with a
            particular disease, in order to identify trends in the market and inform overall animal
            care outcomes.
          </li>
          <li>
            We work with manufacturers on targeted manufacturer marketing programs. Your
            participation in any targeted manufacturer marketing program is on an opt-in basis, and
            any non-de-identified data would only be shared with manufacturers with your consent.
          </li>
        </ul>
      </p>
      <h2>What we do with data — Pet and horse owners</h2>
      <p>
        For pet and horse owners accessing veterinarian storefronts or otherwise using our services,
        we collect data to help improve your user experience, and help identify and close gaps in
        your animal&apos;s care. Information we collect provides insights, which we help turn into
        action, which means healthier and happier pets and horses!
        <ul>
          <li>
            We share information with your veterinarian, including any medication or prescription
            diet purchases you make, so that it can be added to your animal&apos;s medical records.
          </li>
          <li>
            We use your information to provide customer service to you, like checking order status
            or working with your veterinarian to confirm your prescription.
          </li>
          <li>
            We do not share your personal information in any way that is not covered in this policy
            without a lawful basis for doing so, such as getting your consent first.
          </li>
        </ul>
      </p>
      <h2>What we do with data — All users</h2>
      <p>
        When you use our products and services, we may also use your information:
        <ul>
          <li>
            In order to maintain and improve our products and services by making sure they are
            working well. For example, knowing which pages of our website frequently result in calls
            from confused customers helps us know where we need to improve our site design.
          </li>
          <li>
            To help develop new products and services. For example, tracking the number of calls for
            basic pharmacy refills enabled us to develop a Refill App to make things easier on our
            pet owners.
          </li>
          <li>
            In order to personalize your experience with our products and services according to the
            information we collect. This means we talk to cat owners about cats, and dog owners
            about dogs — or we remind you when your prescription needs to be refilled so that it
            arrives before your current medication runs out.
          </li>
          <li>
            To provide services to you, including through our contractors. This includes sharing
            your address with providers of delivery services like UPS or FedEx, or sharing
            information with third parties that provide ONVET with email solutions, cloud hosting
            services, payment processing, website support and maintenance, and legal, regulatory, or
            audit services.
          </li>
          <li>
            For regulatory compliance. We maintain prescription records as required by state and
            federal law, and we may share your personal information to comply with state and
            provincial boards of pharmacy, DEA, FDA, and other regulatory agency laws, rules, and
            regulations. This can include providing required personal information in connection with
            mandatory controlled substance prescription disclosures to state regulatory agencies,
            and providing disclosures of prescription records in response to state or federal
            regulatory agency request.
          </li>
          <li>
            For legal reasons. We may share your personal information to comply with inquiries from
            governmental authorities, or to protect, defend, exercise legal rights or claims, in
            connection with end user license agreements, terms of service or other applicable
            agreements, or as otherwise required by law. If we are legally required to share your
            information, we will attempt to notify you, unless such notification would violate law
            or court order.
          </li>
          <li>
            In the event of business transfer. If ONVET is involved in a sale of assets, merger,
            acquisition, business transfer, or other change of control, we may share your
            information with other entities involved. We will require any such successor business
            entity to honor this Privacy Policy, and we will notify you of any changes that could
            affect your information. In the event of a business transfer, unless we are otherwise
            required by law to retain or provide your information to the other entity, you may
            request that your information be deleted from our records.
          </li>
        </ul>
      </p>
      <h2>Managing your data</h2>
      <p>
        <ul>
          <li>
            Websites. You can adjust and manage the privacy of your information via our websites,
            which allow you to opt out of certain features that we offer. By adjusting your device
            or browser settings, you can manage information by adjusting your personal profile, your
            subscriptions, your cookies, and your location services.
          </li>
          <ul style={{ paddingLeft: "5%" }}>
            <li>
              <strong>Email Communication Preferences.</strong> You can opt out of emails through
              links in the emails you receive from us. You may not opt-out of service-related
              communications (e.g., account verification, transactional communications,
              changes/updates to features of the Services, technical and security notices).
            </li>
          </ul>

          <li>
            Products. All use of products is subject to the user consent in the product&apos;s
            subscription terms and conditions for each product. You can withdraw your consent at any
            time by unsubscribing from the product and discontinuing use.
          </li>
          <li>
            By Contacting Us. You can also contact us anytime at
            {/* TODO(mchi): change this email to mail to */}
            <a className="inline-link" href="mailto:support@covetrus.com">
              info@onvet.ca
            </a>
            if you would like to change or update your personal information. In some cases, we may
            have to keep certain information for legal or business reasons (like prescription
            records).
          </li>
        </ul>
      </p>
      <h2>How we protect your data — Technical safeguards</h2>
      <p>
        The security and confidentiality of your information is extremely important to us. We have
        implemented technical, administrative, and physical security measures to protect your
        information. As part of our efforts to protect your information, we have technical
        safeguards in place across our products and services, which may include the following:
        <ul>
          <li>
            Requiring a password or a unique token in order for you to access your personal
            information;
          </li>
          <li>Using firewalls to protect information held in our servers;</li>
          <li>
            Utilizing Secure Socket Layer (SSL) encryption in transmitting personal information to
            our servers;
          </li>
          <li>
            Limiting the number of personnel who have potential access to personal information; and
          </li>
          <li>
            Routinely backing up the systems to protect the integrity of personal information.
          </li>
        </ul>
      </p>
      <p>
        There is always some risk that an unauthorized third party may find a way around our
        security systems. In the event of a security breach, the first thing we would do is notify
        you. This notice would include what happened, what information was breached, what ONVET is
        doing to respond, and who to contact with questions.
      </p>
      <h2>Protecting children&apos;s privacy</h2>
      <p>
        ONVET does not knowingly sell products to, solicit, collect or retain personal information
        about children under the age of 18 without parental consent. If we learn that a child under
        18 has submitted information to us without parental consent, we will take all reasonable
        measures to delete it from our databases and not use the information for any purpose (except
        where necessary to protect the child or others). If you become aware of any personally
        identifiable information we have collected from children under 18, please contact us at
        <a className="inline-link" href="mailto:info@onvet.ca">
          info@onvet.ca
        </a>
      </p>
      <h2>About this policy</h2>
      <p>
        We comply with data privacy regulations applicable in the jurisdictions in which we operate.
        We review and may revise this policy from time to time as we add new features and services,
        as laws change, and as industry best practices evolve. We indicate the date the most recent
        changes were published on this page. If we update this privacy policy with significant
        changes, we will let you know, for example, by placing a note on our website. Please review
        this policy periodically, and especially before you provide any information to us.
      </p>
      <h2>How to contact us with questions</h2>
      If you have any questions about this policy or our practices at ONVET, please contact us at
      <a className="inline-link" href="mailto:info@onvet.ca">
        info@onvet.ca
      </a>
    </div>
  );
};

export default PrivacyPolicy;
