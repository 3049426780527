import React from "react";

import "./css/Footer.scss";
import Logo from "./images/logo.jpeg";
import facebook from "./images/facebook.png";
import twitter from "./images/twitter.png";
import instagram from "./images/instagram.png";
import { Link } from "wouter";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-logo-container">
        <img className="logo" src={Logo} alt="Onvet"></img>
        <p>©2022, ONVET Medical Canada Inc. All Rights Reserved</p>
      </div>

      <div className="page-links">
        <div className="terms-and-policy">
          <Link className="nav-link" to="/privacy">
            Privacy Policy
          </Link>
          <Link className="nav-link" to="/terms">
            Terms of Service
          </Link>
        </div>
        <div className="resource-links">
          <Link className="nav-link" to="/promotions">
            Promotions
          </Link>
          <Link className="nav-link" to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
      <div className="social-links">
        <a href="https://www.instagram.com/onvetmedical" target="_blank" rel="noreferrer">
          <img src={instagram}></img>
        </a>
        <a href="https://twitter.com/OnvetM" target="_blank" rel="noreferrer">
          <img src={twitter}></img>
        </a>
        <a href="https://www.facebook.com/ONVETCANADA" target="_blank" rel="noreferrer">
          <img src={facebook}></img>
        </a>
      </div>
    </div>
  );
};

export default Footer;
