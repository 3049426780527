import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import "../css/repairForm.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";

export const RepairForm = () => {
  return (
    <div className="container repair-form">
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Service Repair Form</h1>
      <div className="repair-form-wrapper">
        <Form className="repair-form">
          <h2>Return To</h2>
          <Row className="mb-3">
            <Form.Group controlId="contact">
              <Form.Label className="form-required">Contact Name</Form.Label>
              <Form.Control required type="text" placeholder="Contact Name" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="officeName">
              <Form.Label className="form-required">Office Name</Form.Label>
              <Form.Control required type="text" placeholder="Office Name" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="email">
              <Form.Label className="form-required">Email Address</Form.Label>
              <Form.Control required type="text" placeholder="Email Address" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="phoneNumber">
              <Form.Label className="form-required">Phone Number</Form.Label>
              <Form.Control required type="text" placeholder="Phone Number" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="officeAddress">
              <Form.Label className="form-required">Office Address</Form.Label>
              <Form.Control required type="text" placeholder="Office Address" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="country">
                <Form.Label className="form-required">Country</Form.Label>
                <Form.Control required type="text" placeholder="Country" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="city">
                <Form.Label className="form-required">City</Form.Label>
                <Form.Control required type="text" placeholder="City" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="postal">
              <Form.Label className="form-required">Zip code</Form.Label>
              <Form.Control required type="text" placeholder="Postal Code" />
            </Form.Group>
          </Row>
        </Form>
        <Form className="repair-form">
          <h2>Return Item</h2>
          <Row className="mb-3">
            <Form.Group controlId="modelNumber">
              <Form.Label className="form-required">Model Number</Form.Label>
              <Form.Control required type="text" placeholder="Model Number" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="serialNumber">
              <Form.Label className="form-required">Serial Number</Form.Label>
              <Form.Control required type="text" placeholder="Serial Number" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="reason">
              <Form.Label>Reason</Form.Label>
              <Form.Control type="textarea" style={{ height: "100px" }} />
            </Form.Group>
          </Row>
        </Form>
      </div>

      <div className="repair-form-submit-button">
        <Button>Submit Form</Button>
      </div>
    </div>
  );
};
