import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import NavigationBreadcrumb from "../NavigationBreadcrumb";

import "../css/returnForm.scss";

export const ReturnForm = () => {
  return (
    <div className="container return-form">
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Return/Exchange Form</h1>
      <Form>
        <div className="contact-person-inputs">
          <Form.Group controlId="contact">
            <Form.Label className="form-required">Contact Person</Form.Label>
            <Form.Control required type="text" placeholder="Contact Person" />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label className="form-required">Email</Form.Label>
            <Form.Control required type="text" placeholder="Email" />
          </Form.Group>
          <Form.Group controlId="officeName">
            <Form.Label className="form-required">Office Name</Form.Label>
            <Form.Control required type="text" placeholder="Office Name" />
          </Form.Group>
          <Form.Group controlId="officePhone">
            <Form.Label className="form-required">Office Phone</Form.Label>
            <Form.Control required type="text" placeholder="Office Phone" />
          </Form.Group>
        </div>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="country">
              <Form.Label className="form-required">Country</Form.Label>
              <Form.Control required type="text" placeholder="Country" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="state">
              <Form.Label className="form-required">State/Province</Form.Label>
              <Form.Control required type="text" placeholder="State/Province" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="city">
              <Form.Label className="form-required">City</Form.Label>
              <Form.Control required type="text" placeholder="City" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="postal">
              <Form.Label className="form-required">Postal Code</Form.Label>
              <Form.Control required type="text" placeholder="Postal Code" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="street">
              <Form.Label className="form-required">Street</Form.Label>
              <Form.Control required type="text" placeholder="Street" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="invoice">
              <Form.Label className="form-required">Invoice#</Form.Label>
              <Form.Control required type="text" placeholder="Invoice#" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h2>Please list the return item(s) and reason(s):</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="qty">
              <Form.Label className="form-required">Qty</Form.Label>
              <Form.Control required type="text" placeholder="Qty" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="product-code">
              <Form.Label className="form-required">Product Code</Form.Label>
              <Form.Control required type="text" placeholder="Product Code" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="reason">
              <Form.Label>Reason</Form.Label>
              <Form.Control type="textarea" style={{ height: "100px" }} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h2>What do you want to exchange for (if applicable)?</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="qty-exchange">
              <Form.Label className="form-required">Qty</Form.Label>
              <Form.Control required type="text" placeholder="Qty" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="product-code-exchange">
              <Form.Label className="form-required">Product Code</Form.Label>
              <Form.Control required type="text" placeholder="Product Code" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="model-description">
              <Form.Label className="form-required">Model/Description</Form.Label>
              <Form.Control required type="text" placeholder="Model/Description" />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <div className="return-form-submit-button">
        <Button>Submit Form</Button>
      </div>
    </div>
  );
};
