import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation } from "wouter";

import "./css/navBreadcrumb.scss";

const NavigationBreadcrumb = () => {
  const [location] = useLocation();
  const splitLocation = location.split("/").filter((location) => location.length > 0);

  return (
    <div className="navigation-breadcrumb">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        {splitLocation.map((link, index) => {
          return (
            <Breadcrumb.Item
              key={`path-${link}`}
              linkAs={index === splitLocation.length - 1 ? undefined : Link}
              linkProps={
                index === splitLocation.length - 1
                  ? undefined
                  : {
                      to: `/${link}`,
                    }
              }
              className="nav-breadcrumb-item"
              active={index === splitLocation.length - 1 ? true : false}>
              {link}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default NavigationBreadcrumb;
