import { toPng } from "html-to-image";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import React, { useRef, useState } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";

import "../css/Contact.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";

import contactUsImg from "../images/contactUs.jpeg";
import { GoogleCaptcha } from "../components/GoogleCaptcha";
import { Link } from "wouter";
import axios from "axios";
import { useForm } from "react-hook-form";

const Contact = () => {
  const questionConcernOptions = [
    {
      value: "account-setup",
      label: "Account set up or existing account updates",
    },
    {
      value: "quotes",
      label: "Quotation on new build, remodel or expansion",
    },
    {
      value: "existing-order",
      label: "Your existing order",
    },
    {
      value: "tech-support",
      label: "Technical support on equipment",
    },
    {
      value: "promotions",
      label: "Questions about our promotions",
    },
    {
      value: "product-complaint",
      label: "Product complaint",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const ref = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [contactFormRes, setContactFormRes] = useState({
    firstName: "",
    lastName: "",
    practice: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onInputChanged = (name, e) => {
    setContactFormRes({
      ...contactFormRes,
      [name]: e.target.value,
    });
  };

  const clearForm = () => {
    setContactFormRes({
      firstName: "",
      lastName: "",
      practice: "",
      phone: "",
      email: "",
      message: "",
    });
  };

  const onSelectChanged = (selectedOption) => {
    setSelectValue(selectedOption.value);
  };

  const { register, formState } = useForm({
    mode: "onTouched",
  });

  const formValid = recaptchaValid && !!selectValue && formState.isValid;
  console.log(formState.isValid);

  const onFormSubmit = async () => {
    try {
      if (ref.current === null) {
        return;
      }
      setIsSubmitting(true);

      const blob = await toPng(ref.current, {
        cacheBust: true,
        backgroundColor: "white",
        pixelRatio: 1,
      });

      const imageId = uuidv4();
      const imageName = `contact-${imageId}.png`;

      const resp = await axios.post(
        "https://us-central1-onvet-c7af7.cloudfunctions.net/widgets/uploadImage",
        {
          imageData: [
            {
              title: imageName,
              base64String: blob,
            },
          ],
        }
      );

      if (resp.status === 200) {
        setShowToast(true);
      }
    } catch (error) {
      console.error("Failed to submit contact form.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container contact">
      <Modal show={showToast} onHide={() => setShowToast(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your message has been sent successfully. We will get back to you soon.
        </Modal.Body>
      </Modal>

      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Contact Us</h1>
      <img src={contactUsImg} alt="Contact Us"></img>
      <div className="metadata">
        <h2>Hours of Operation</h2>
        <p>Monday to Friday: 9:00AM - 6:00PM ET</p>
        <h2>Phone</h2>
        <p>
          Your call will always be answered directly by an ONVET employee, who will assist you with
          whatever you need.
          <a className="phone-link" href="tel:1-888-655-0106">
            1-888-655-0106
          </a>
        </p>
        <h2>Fax</h2>
        <p>1-647-366-9951</p>
      </div>
      <h2>
        WE CAN BE REACHED TOLL-FREE AT
        <a className="phone-link" href="tel:1-888-655-0106">
          1-888-655-0106
        </a>
      </h2>
      <p className="contact-application-message">
        Looking to become a customer?
        <Link
          style={{
            display: "inline",
            padding: "0",
            paddingRight: "2px",
            marginLeft: "4px",
          }}
          className="nav-link"
          to="/new-customer">
          Complete our application.
        </Link>
        If you already are an ONVET customer, but need access to purchase online, please contact
        your Customer Service Specialist
      </p>
      <p className="contact-form-message">
        Do you have a question, comment or concern? Please fill out a brief form below to get
        started.
      </p>
      <Form
        ref={ref}
        style={{
          padding: "20px 0",
        }}>
        <div className="contact-form">
          <div className="form-input">
            <label className="form-required" htmlFor="firstName">
              First Name
            </label>
            <input
              {...register("firstName", { required: true })}
              className="text-input"
              id="firstName"
              type="text"
              placeholder="First name"
              value={contactFormRes.firstName}
              onChange={(e) => onInputChanged("firstName", e)}
            />
          </div>
          <div className="form-input">
            <label className="form-required" htmlFor="lastName">
              Last Name
            </label>
            <input
              {...register("lastName", { required: true })}
              className="text-input"
              id="lastName"
              type="text"
              placeholder="Last name"
              value={contactFormRes.lastName}
              onChange={(e) => onInputChanged("lastName", e)}
            />
          </div>
          <div className="form-input">
            <label className="form-required" htmlFor="practice">
              Practice/Company Name
            </label>
            <input
              {...register("practice", { required: true })}
              className="text-input"
              id="practice"
              type="text"
              placeholder="Practice/Company Name"
              value={contactFormRes.practice}
              onChange={(e) => onInputChanged("practice", e)}
            />
          </div>
          <div className="form-input">
            <label className="form-required" htmlFor="phone">
              Phone Number
            </label>
            <input
              {...register("phone", { required: true })}
              className="text-input"
              id="phone"
              type="tel"
              placeholder="Phone Number"
              value={contactFormRes.phone}
              onChange={(e) => onInputChanged("phone", e)}
            />
          </div>
          <div className="form-input">
            <label className="form-required" htmlFor="email">
              Email
            </label>
            <input
              {...register("email", { required: true })}
              className="text-input"
              id="email"
              type="email"
              placeholder="Email"
              value={contactFormRes.email}
              onChange={(e) => onInputChanged("email", e)}
            />
          </div>
          <div className="form-input">
            <label className="form-required" htmlFor="questionConcern">
              Inquiry Type
            </label>
            <Select
              id="questionConcern"
              options={questionConcernOptions}
              onChange={onSelectChanged}
            />
          </div>
        </div>
        <div className="form-input">
          <label className="form-required" htmlFor="message">
            Message
          </label>
          <textarea
            {...register("Message")}
            placeholder="Leave a message here"
            id="message"
            rows="5"
            cols="50"
            onChange={(e) => onInputChanged("message", e)}
            value={contactFormRes.message}></textarea>
        </div>
      </Form>
      <div className="captcha">
        <GoogleCaptcha
          onSuccess={() => setRecaptchaValid(true)}
          onFailure={() => setRecaptchaValid(false)}></GoogleCaptcha>
      </div>

      <div className="form-control-buttons">
        <Button
          disabled={!formValid || isSubmitting}
          onClick={onFormSubmit}
          variant="success"
          className="form-button">
          {isSubmitting ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Submitting...</span>
            </Spinner>
          ) : (
            "Submit"
          )}
        </Button>
        <Button onClick={() => clearForm()} variant="danger" className="form-button">
          Reset Form
        </Button>
      </div>
    </div>
  );
};

export default Contact;
