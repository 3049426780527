import React from "react";
import { Carousel } from "react-bootstrap";

import "../css/Home.scss";
import { useQuery } from "react-query";
import { getHomeSliders } from "../utils/cms";

const Home = () => {
  const { data } = useQuery("homeSlider", getHomeSliders, {
    refetchOnWindowFocus: false,
  });
  // console.log(data);

  return (
    <div>
      <div className="slider">
        <Carousel>
          {data &&
            data.map((slider) => (
              <Carousel.Item key={slider.id} className="slider-item">
                <img className="d-block w-100" src={`https:${slider.imageUrl}`} alt="" />
                <Carousel.Caption className="carousel-caption">
                  <h3>{slider.label}</h3>
                  {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>
            ))}

          {/* <Carousel.Item className="slider-item">
            <img className="d-block w-100" src={image2} alt="Second slide" />
  
            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="slider-item">
            <img className="d-block w-100" src={image3} alt="Third slide" />
  
            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
        <div className="home-content">
          <h1>
            There is power in partnership <span className="home-subtitle">with Onvet Canada.</span>
          </h1>
          <h1>
            We focus on <span className="home-subtitle">three core areas</span> to make ourselves a
            valuable partner for your practice.
          </h1>
          <div className="home-subsections">
            <h2 className="home-subtitle1">Team</h2>
            <p>ONVET is a Canadian Owned Business that values our PEOPLE and yours.</p>
            <h2 className="home-subtitle1">Service</h2>
            <p>
              Our goal is to be a true business partner that strives to consistently exceed your
              expectations. We are willing to take the extra steps to make you satisfied.
            </p>
            <h2 className="home-subtitle1">Products</h2>
            <p>
              We cooperate with vendors who advocate for the importance of the veterinarian&apos;s
              recommendation of products that provide quality, savings, and value.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
