import React from "react";
import { useQuery } from "react-query";
import { Link } from "wouter";

import "../css/Product.scss";
import NavigationBreadcrumb from "../NavigationBreadcrumb";
import { getProductCategories } from "../utils/cms";

const Products = () => {
  const { data } = useQuery("productCategory", getProductCategories, {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="container products">
      <NavigationBreadcrumb></NavigationBreadcrumb>
      <h1>Products</h1>
      <div className="products-wrapper">
        {data &&
          data.length > 0 &&
          data.map((product) => {
            return (
              <Link key={product.id} to={`/contact`}>
                <div className="product-category">
                  <h2>{product.name}</h2>
                  <img src={`https:${product.imageUrl}`} alt={product.name}></img>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Products;
