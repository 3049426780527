/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const GoogleCaptcha = (props) => {
  const { onSuccess, onFailure } = props;

  const onChange = async (token) => {
    try {
      const resultJson = await fetch(
        `https://us-central1-onvet-c7af7.cloudfunctions.net/widgets/recaptcha?token=${token}`,
        {
          method: "GET",
        }
      );

      const result = await resultJson.json();

      if (result.status === 200 && result.success) {
        onSuccess();
      } else {
        if (onFailure) {
          onFailure();
        }
      }
    } catch (error) {
      console.error("Failed to validate.");
      if (onFailure) {
        onFailure();
      }
    }
  };

  return <ReCAPTCHA type="image" sitekey={process.env.REACT_APP_CAPTCHA_API} onChange={onChange} />;
};
